import type { I18nLocale } from "@/modules/i18n/types"

import { getAssetImportUrl } from "@/modules/staticAssets/getAssetImportUrl"

import flagCA from "./assets/ca.png"
import flagCS from "./assets/cs.png"
import flagDA from "./assets/da.png"
import flagDE from "./assets/de.png"
import flagEN from "./assets/en.png"
import flagES from "./assets/es.png"
import flagFI from "./assets/fi.png"
import flagFR from "./assets/fr.png"
import flagGL from "./assets/gl.png"
import flagIT from "./assets/it.png"
import flagNL from "./assets/nl.png"
import flagNO from "./assets/no.png"
import flagPL from "./assets/pl.png"
import flagPT from "./assets/pt.png"
import flagSV from "./assets/sv.png"

export const FLAG_ASSET: Record<I18nLocale, string> = {
  ca: getAssetImportUrl(flagCA),
  cs: getAssetImportUrl(flagCS),
  da: getAssetImportUrl(flagDA),
  de: getAssetImportUrl(flagDE),
  en: getAssetImportUrl(flagEN),
  es: getAssetImportUrl(flagES),
  fi: getAssetImportUrl(flagFI),
  fr: getAssetImportUrl(flagFR),
  gl: getAssetImportUrl(flagGL),
  it: getAssetImportUrl(flagIT),
  nl: getAssetImportUrl(flagNL),
  no: getAssetImportUrl(flagNO),
  pl: getAssetImportUrl(flagPL),
  pt: getAssetImportUrl(flagPT),
  sv: getAssetImportUrl(flagSV),
}
