import type { I18nLocale } from "@/modules/i18n/types"

import { getUpdatedHrefWithLocale } from "@/modules/routing/helpers/getUpdatedHrefWithLocale"

type GetLocalesPathnamesParams = {
  locales: I18nLocale[]
  locale: I18nLocale
  pathname: string
}

export function getLocalesPathnames({
  locale,
  locales,
  pathname,
}: GetLocalesPathnamesParams): Partial<Record<I18nLocale, string>> {
  return locales.reduce(
    (previous, allowedLocale) => ({
      ...previous,
      [allowedLocale]: getUpdatedHrefWithLocale(pathname, locale, allowedLocale),
    }),
    {}
  )
}
