import type { FunctionComponent } from "react"

import { clsx } from "clsx"
import dynamic from "next/dynamic"

import { useCareerCenterFetch } from "@/modules/careerCenter/hooks/useCareerCenterFetch"

import styles from "./Brand.module.css"

export type BrandProps = {
  from: "footer" | "header"
  JTLogoClassName?: string
}

const CCLogoDynamic = dynamic(() => import("./CCLogo").then(mod => mod.CCLogo))
const JTLogoDynamic = dynamic(() => import("./JTLogo").then(mod => mod.JTLogo))

export const Brand: FunctionComponent<BrandProps> = ({ from, JTLogoClassName }) => {
  const { dataCareerCenter } = useCareerCenterFetch()

  return (
    <div
      className={clsx(styles["app-Brand"], {
        [styles["app-Brand--footer"]]: from === "footer",
      })}
    >
      {dataCareerCenter?.logo ? (
        <CCLogoDynamic
          name={dataCareerCenter?.careerCenterConf?.careerCenterNameLocalized || "Career Center"}
          logo={dataCareerCenter.logo}
          secondaryLogo={dataCareerCenter?.secondaryLogo}
          from={from}
        />
      ) : (
        <JTLogoDynamic from={from} JTLogoClassName={JTLogoClassName} />
      )}
    </div>
  )
}
